.blog-section {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background: radial-gradient(42% 25% at 50% 0%, rgba(157, 137, 255, 0.1), rgba(1, 3, 20, 0) 100%),
    radial-gradient(
      200% 74% at 50% 42%,
      rgb(1, 2, 20) 70%,
      rgb(96, 48, 220) 85%,
      rgb(206, 192, 249) 100%
    );
}

.blog-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
  margin-top: 0px;
  text-align: center;
  color: white;
}

.blog-heading {
  color: rgb(255, 255, 255);
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -1%;
  text-align: left;
}

.blog-container {
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(1, 3, 20, 1) 5%,
      rgba(157, 137, 255, 1) 50%,
      rgba(1, 3, 20, 1) 95%
    )
    1;
  padding: 100px 0px;
  padding-bottom: 0px;
}

.blog-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 4vw;
  border-top: 1px solid rgba(190, 167, 255, 0.3);
}

.blog-item:last-child {
  border-bottom: 1px solid rgba(190, 167, 255, 0.3);
}

.blog-image img {
  width: 100%;
  height: auto;
  border-radius: 20px;
  min-width: 300px;
  max-width: 688px;
  max-height: 380px;
}

.blog-image {
  position: relative;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3vw 1.5vw;
  max-width: 748px;
  border-left: 1px solid rgba(190, 167, 255, 0.3);
  border-right: 1px solid rgba(190, 167, 255, 0.3);
}

.blog-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  flex: 1;
  min-width: 300px;
  padding: 3vw 1.5vw;
  max-width: 748px;
  border-left: 1px solid rgba(190, 167, 255, 0.3);
  border-right: 1px solid rgba(190, 167, 255, 0.3);
}

.blog-heading {
  color: rgb(255, 255, 255);
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -1%;
  text-align: left;
  margin: 0px;
  margin-bottom: 40px;
}

.blog-date {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.24);
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.2);
  display: block;
  width: fit-content;
  padding: 10px;
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: -1%;
  text-align: left;
  text-transform: uppercase;
}

.blog-text {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -1%;
  text-align: left;
  margin: 0px;
}

@media (max-width: 768px) {
  .blog-container {
    grid-template-columns: 1fr;
  }
}

.plus {
  position: absolute;
  color: white(190, 167, 255, 0.6);
  font-size: 15px;
  display: block;
  width: 10px;
  height: 10px;
  background-image: url('./../../../Images/Plus.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.plus-left-top {
  left: -5px;
  top: -5px;
}
.plus-left-bottom {
  left: -5px;
  bottom: -5px;
}
.plus-right-top {
  top: -5px;
  right: -5px;
}
.plus-right-bottom {
  bottom: -5px;
  right: -5px;
}
