.privacy-policy-container {
  max-width: 1436px;
  padding: 50px 20px;
  background-color: #010214;
  border: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(1, 3, 20, 1) 5%,
      rgba(157, 137, 255, 1) 50%,
      rgba(1, 3, 20, 1) 95%
    )
    1;
  box-sizing: border-box;
  border-radius: 50px;
  background: radial-gradient(44% 9% at 50% 100%, rgba(157, 137, 255, 0.1), rgba(1, 3, 20, 0) 100%),
    radial-gradient(42% 25% at 50% 0%, rgba(157, 137, 255, 0.1), rgba(1, 3, 20, 0) 100%);
}

.privacy-policy-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.privacy-policy-title {
  margin: 100px 0px;
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-weight: 700;
  line-height: 77px;
  letter-spacing: 0%;
  text-align: center;
}

.privacy-section {
  margin-bottom: 40px;
}

.privacy-heading {
  color: rgb(255, 255, 255);
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0px;
  margin-bottom: 40px;
}

.privacy-text {
  font-size: 1rem;
  color: #9fa2b9;
  line-height: 1.6;
}

.privacy-list {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  padding-left: 25px;
}
