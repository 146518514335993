.home-section {
  border-radius: 50px;
  background: url('./../../../Images/Grid.png'),
    radial-gradient(
      145% 125% at 50% 0%,
      rgb(1, 2, 20) 50%,
      rgb(96, 48, 220) 75%,
      rgb(206, 192, 249) 100%
    );
  padding: 100px 20px;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.main-heading {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 40px;
  line-height: 1.2;
}

.highlight {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 20px;
  background: rgb(10, 6, 34);
  padding: 6px;
  color: #6d58fc; /* Цвет выделения слова "West" */
}

.subheading {
  margin-bottom: 60px;
  color: rgb(159, 162, 185);
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0%;
  text-align: center;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.primary-btn {
  background-color: #fff;
  color: #000;
  padding: 15px 30px;
  border-radius: 30px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: rgb(1, 2, 20);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0%;
  display: flex;
  gap: 5px;
}

.primary-btn i,
.secondary-btn i,
.explore-btn i {
  background-image: url('./../../../Images/RightRow.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  width: 10px;
  height: 18px;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
  position: relative;
  top: 3px;
  left: 0px;
  transition: left 0.3s;
}

.primary-btn:hover i,
.secondary-btn:hover i,
.explore-btn:hover i {
  left: 5px;
}

.secondary-btn {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: left;
  display: flex;
}

.secondary-btn i {
  background-image: url('./../../../Images/YTmini.svg');
  margin-left: 5px;
  width: 25px;
}

.primary-btn:hover,
.secondary-btn:hover {
}

.social-icons {
  margin-top: 40px;
}

.social-icons .icon {
  margin: 0 10px;
  text-decoration: none;
  color: #fff;
  font-size: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 20px;
  display: inline-block;
}

.social-icons .icon:hover {
  color: #6d58fc; /* Цвет на ховер */
}

.discord {
  background-image: url('./../../../Images/Discord.svg');
}

.telegram {
  background-image: url('./../../../Images/Telegram.svg');
}

.X {
  background-image: url('./../../../Images/X.svg');
}

/*________________________________________*/

.stats-section {
  background-color: transparent; /* Темный фон */
  padding: 50px 0px;
}

.stats-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.stat-card {
  background-color: transparent; /* Темный цвет для карточек */
  padding: 20px;
  border-radius: 10px;
  flex: 1;
  max-width: 320px;
  text-align: left;
  color: #fff;
  border: 1px solid rgba(190, 167, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.highlighted-card {
  background-color: #00d084; /* Цвет для выделенной карточки */
}

.stat-card h3,
.stat-card h4 {
  margin-bottom: 15px;
  color: rgb(159, 162, 185);
  /*font-family: Aktiv Grotesk Ex;*/
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0%;
  margin-top: 0;
}

.highlighted-card h3 {
  color: rgb(0, 0, 0);
}

.stat-card p {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
}

.trustpilot-logo {
  height: 20px;
  margin-right: 10px;
  padding-left: 25px;
  background-image: url('./../../../Images/Star.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}

.stars {
  font-size: 1.2rem;
  color: #fff;
  margin-top: 10px;
  display: flex;
  gap: 2px;
}

.stars span {
  width: 25px;
  height: 25px;
  background-image: url('./../../../Images/StarGreen.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  display: inline-block;
  background-color: white;
  border-radius: 5px;
}

.rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .stats-container {
    flex-direction: column;
    align-items: center;
  }

  .stat-card {
    width: 100%;
    margin-bottom: 20px;
  }
}

/*_______________________________________*/

.about-section-title {
  color: rgb(255, 255, 255);
  /*font-family: Aktiv Grotesk Ex;*/
  margin: 0;
  margin-bottom: 80px;
  font-size: 60px;
  font-weight: 700;
  line-height: 77px;
  letter-spacing: 0%;
  text-align: center;
}

.about-section {
  border-radius: 50px;

  background: radial-gradient(
    110% 85% at 50% 100%,
    rgb(1, 2, 20) 80%,
    rgb(96, 48, 220) 115%,
    rgb(206, 192, 249) 125%
  );
  padding: 100px 20px;
  color: #fff;
}

.about-section-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

.about-text {
  flex: 1;
  padding-right: 20px;
}

.about-text h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #fff;
}

.about-text h3 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  /*font-family: Aktiv Grotesk Ex;*/
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: left;
}

.about-text p {
  font-size: 1rem;
  line-height: 1.6;
  color: rgb(159, 162, 185);
  /*font-family: Aktiv Grotesk Ex;*/
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.about-card {
  padding: 20px;
  flex: 1;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  background: linear-gradient(90deg, rgb(10, 6, 34), rgba(10, 6, 34, 0.2) 100%);
  display: flex;
  gap: 3vw;
  flex-wrap: wrap;
}

.about-card-static {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.trade-list {
  margin: 20px 0;
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: relative;
}

.trade-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid rgb(64, 182, 107);
  border-radius: 10px;
  background: rgba(64, 182, 107, 0.2);
  padding: 14px 24px;
  margin-left: 40px;
  min-width: 265px;
}

.trade-item-sell {
  box-sizing: border-box;
  border: 1px dashed rgb(181, 41, 58);
  border-radius: 10px;
  background: rgba(181, 41, 58, 0.2);
  margin-left: 0px;
  position: relative;
  right: 0px;
  bottom: 0px;
  animation: tradeItemBuyAnimation 5s infinite;
}

.trade-item-buy {
  border: 1px dashed rgb(64, 182, 107);
  margin-left: 0px;
  position: relative;
  right: 0px;
  top: 0px;
  animation: tradeItemSellAnimation 5s infinite;
}

.profit {
  position: absolute;
  padding: 10px 24px;
  background-color: #2c0cfd;
  border: 1px dashed #d0c9fe;
  border-radius: 25px;
  top: 196px;
  right: 40%;
  opacity: 0;
  transform: rotate(-20deg);
  animation: profitAnimation 5s infinite;
}

@keyframes profitAnimation {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  45% {
    opacity: 0;
    transform: scale(1);
  }
  55% {
    opacity: 1;
    transform: scale(1.3);
  }
  65% {
    opacity: 1;
    transform: rotate(-10deg), scale(1);
  }
  75% {
    opacity: 1;
    transform: rotate(10deg);
  }
  85% {
    opacity: 1;
    transform: rotate(-10deg);
  }
  100% {
    opacity: 0;
    transform: rotate(10deg);
  }
}

@keyframes tradeItemBuyAnimation {
  0% {
    right: -450px;
    bottom: 0px;
  }
  20% {
    right: 0px;
    opacity: 1;
    transform: scale(1);
  }
  30% {
    transform: scale(1);
  }
  40% {
    opacity: 1;
    bottom: 0px;
    transform: scale(1.2);
  }
  60% {
    bottom: -65px;
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    bottom: -65px;
    opacity: 0;
  }
}

@keyframes tradeItemSellAnimation {
  0% {
    right: -450px;
    top: 0px;
  }
  20% {
    right: 0px;
    opacity: 1;
    transform: scale(1);
  }
  30% {
    transform: scale(1);
  }
  40% {
    opacity: 1;
    top: 0px;
    transform: scale(1.2);
  }
  60% {
    top: -65px;
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    top: -65px;
    opacity: 0;
  }
}

.animated-element {
  animation: myAnimation 3s infinite;
}

.trade-btn {
  font-weight: bold;
}

.buy {
  color: #00d084;
}

.sell {
  color: #d93025;
}

.price {
  flex: 1;
  text-align: center;
}

.change {
  font-weight: bold;
}

.positive {
  color: #00d084; /* Зеленый цвет */
}

.negative {
  color: #d93025; /* Красный цвет */
}

.disclaimer {
  margin-top: 20px;
  font-size: 0.8rem;
  text-align: left;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .about-text {
    padding-right: 0;
    margin-bottom: 30px;
  }

  .about-card {
    width: 100%;
  }
}

@media (max-width: 520px) {
  .trade-item {
    margin-left: 0;
  }
}

/*____________________________________________*/

.security-section {
  background-color: transparent; /* Темный фон */
  color: #fff;
  margin-bottom: 100px;
  text-align: center;
}

.security-section-container {
  max-width: 1200px;
  margin: 0 auto;
}

.security-title {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-weight: 700;
  line-height: 77px;
  letter-spacing: 0%;
  text-align: center;
  margin: 0px;
}

.security-text {
  color: rgb(255, 255, 255);
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: center;
  margin: 80px 0;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.securitu-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.grid-container {
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;
  flex: 1;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  padding: 20px;
  justify-content: space-between;
}

.grid-item {
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.large-cards {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.large-card h3 {
  color: rgb(159, 162, 185);
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0%;
  text-align: left;
}

.large-card p {
  color: rgb(255, 255, 255);
  font-size: 70px;
  font-weight: 700;
  line-height: 90px;
  letter-spacing: 0%;
  text-align: left;
}

.large-card {
  grid-column: span 1;
  grid-row: span 1;
  text-align: left;
  height: 50%;
}

.uptime-card {
  text-align: left;
  height: 50%;
  box-sizing: border-box;
  border: 1px solid rgba(64, 182, 107, 0.3);
  border-radius: 30px;
  background: rgba(64, 182, 107, 0.2);
  color: rgb(64, 182, 107);
}

.uptime-card h3 {
  display: flex;
  gap: 5px;
}

.small-card {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 10px;
  display: flex;
  min-width: 285px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.small-card p {
  color: rgb(255, 255, 255);
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0;
  flex: 1;
}

.small-card-img {
  background-repeat: no-repeat;
  background-position: center left;
  background-size: contain;
  max-width: 50px;
  height: 40px;
  flex: 1;
}

.small-card:nth-child(1) .small-card-img {
  background-image: url('./../../../Images/Security1.svg');
}
.small-card:nth-child(2) .small-card-img {
  background-image: url('./../../../Images/Security2.svg');
}
.small-card:nth-child(3) .small-card-img {
  background-image: url('./../../../Images/Security3.svg');
}
.small-card:nth-child(4) .small-card-img {
  background-image: url('./../../../Images/Security4.svg');
}

.stat {
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 450px) {
  .large-card,
  .uptime-card {
    grid-column: span 1;
    border-radius: 0px;
  }
  .investors-section {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .investment-info,
  .trades-section {
    border-radius: 0px !important;
  }
  .bottom-buttons {
    padding: 20px;
  }
}

/*_______________________________________________*/

.verify-section {
  background-color: #0f0f1e;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 40px;
  background: transparent;
  border-top: 2px solid purple;
  border-bottom: 2px solid purple;
  border-image: linear-gradient(
      to right,
      rgba(1, 3, 20, 1) 5%,
      rgba(157, 137, 255, 1) 50%,
      rgba(1, 3, 20, 1) 95%
    )
    1;
  border-width: 1px;
}

.verify-section-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
  padding: 30px 90px 30px 90px;
  background: linear-gradient(90deg, rgb(1, 2, 20), rgba(1, 2, 20, 0) 50%, rgb(1, 2, 20) 100%),
    url('./../../../Images/Grid.png');
  background-position: center;
}

.text-content {
  max-width: 50%;
}

.text-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  line-height: 1.5;
}

.text-content p {
  font-size: 1.2rem;
  margin: 40px 0px 60px 0px;
  color: #b0b0b0;
}

.learn-more-btn {
  background-color: #fff;
  color: #000;
  padding: 15px 30px;
  border-radius: 30px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: rgb(1, 2, 20);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: left;
}

.learn-more-btn i {
  background-image: url('./../../../Images/RightRow.svg');
  width: 10px;
  height: 18px;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
}

.learn-more-btn:hover i {
  transform: translateX(5px);
}

.image-content {
  max-width: 40%;
}

.fingerprint-img {
  max-width: 100%;
  height: auto;
  min-width: 250px;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    text-align: center;
  }

  .verify-section-container {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  .text-content {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .image-content {
    max-width: 80%;
  }
}

/*________________________________________________*/

.investors-section {
  color: #fff;
  padding: 80px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: radial-gradient(86% 50% at 50% 0%, rgba(157, 137, 255, 0.1), rgba(1, 3, 20, 0) 100%);
}

.investors-container {
  display: flex;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}

.investment-info {
  text-align: left;
  background-image: url('./../../../Images/Rocket.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  border-radius: 30px;
  max-width: 708px;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
}

.investment-text {
  padding: 60px;
}

.investment-text h3 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0%;
  text-align: left;
}

.investment-text p {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.td-details-p {
  color: rgb(159, 162, 185);
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

.trades-section {
  border-radius: 30px;
  max-width: 708px;
  flex: 1;
  overflow: hidden;
  min-width: 330px;
  border: 1px solid rgba(190, 167, 255, 0.2);
}

.trades-section h4 {
  margin: 60px;
  margin-left: 70px;
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0%;
  text-align: left;
  display: flex;
  gap: 10px;
  align-items: center;
}

.green-dot {
  background-color: #40b66b39;
  min-width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
  background-position: center;
  position: relative;
}

.green-dot::after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #40b66b;
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.trades-table th {
  color: #6d58fc;
}

.join-invest-btn {
  border-radius: 40px;
  background: rgb(255, 255, 255);
  color: #000;
  padding: 15px 30px;
  border-radius: 30px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 90%;
  margin: 20px;
  text-decoration: none;
}

.join-invest-btn span {
  background-image: url('./../../../Images/RightRow.svg');
  width: 10px;
  height: 18px;
  background-position: center -0px;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
}

.join-invest-btn:hover span {
  transform: translateX(5px);
}

.bottom-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  gap: 20px;
  justify-content: center;
  max-width: 1436px;
  width: 100%;
}

.bottom-buttons a {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  padding: 15px 30px;
  border-radius: 30px;
  font-size: 1rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  font-weight: bold;
  text-decoration: none;
  padding: 40px;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  text-align: left;
}

.steps-section h2 {
  margin-top: 0px;
  margin-bottom: 80px;
}

.bottom-buttons a span {
  font-size: 1.5rem;
  transition: transform 0.4s ease;
}

.bottom-buttons a:hover span {
  transform: translateX(5px);
}

.analytics-btn {
  background-color: #1a1a2e;
  flex: 1;
}

.whitepaper-btn {
  flex: 1;
}

.trades-table {
  width: 100%;
  border-collapse: collapse; /* Объединяем границы ячеек */
}

.trades-table th,
.trades-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid rgba(190, 167, 255, 0.2); /* Добавляем границы */
}

.trades-table td {
  background-color: transparent;
  padding: 20px 60px;
  box-sizing: border-box;
  border-right: 1px solid rgba(190, 167, 255, 0.2);
  max-width: 236px;
  max-height: 86px;
}

@media (max-width: 1400px) {
  .trades-table td {
    padding: 20px 30px;
  }
}

@media (max-width: 1220px) {
  .trades-table td {
    padding: 5px 10px;
    font-size: 14px;
  }
  .investment-info {
    border: none;
    border-radius: 30px;
    background: linear-gradient(45deg, rgba(2, 1, 8, 1) 0%, rgba(34, 7, 110, 1) 100%);
  }
  .investment-info,
  .trades-section {
    max-width: 100%;
  }
  .investors-container {
    flex-direction: column;
  }
}

.investors-section h2 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-weight: 700;
  line-height: 77px;
  letter-spacing: 0%;
  text-align: center;
  margin: 0;
  margin-bottom: 80px;
}

.td-details {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.td-details p {
}

@media (max-width: 768px) {
  .investors-container {
    flex-direction: column;
  }

  .investment-info,
  .trades-section {
    max-width: 100%;
  }

  .bottom-buttons {
    flex-direction: column;
    gap: 20px;
  }
}

/*__________________________________________________*/

.steps-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 100px;
}

.steps-section {
  color: rgb(255, 255, 255);
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: center;
}

.step-card {
  flex: 1;
  box-sizing: border-box;
  max-width: 344px;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  background: linear-gradient(180deg, rgba(48, 39, 85, 0.5), rgba(10, 6, 34, 0.5) 100%);
  padding: 60px;
  transition: transform 0.3s ease;
}

.step-card:hover {
  transform: translateY(-5px);
}

.step-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
}

.step-number-home {
  color: rgb(159, 162, 185);
  font-size: 80px;
  font-weight: 700;
  line-height: 102px;
  letter-spacing: 0%;
  text-align: left;
}

.step-icon {
  font-size: 2rem;
}

.step-icon {
  font-size: 2rem;
  width: 60px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.step-icon-1 {
  background-image: url('./../../../Images/StepIMG1.svg');
}
.step-icon-2 {
  background-image: url('./../../../Images/StepIMG2.svg');
}
.step-icon-3 {
  background-image: url('./../../../Images/StepIMG3.svg');
}
.step-icon-4 {
  background-image: url('./../../../Images/StepIMG4.svg');
}

.step-card h3 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0px;
}

.step-card p {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0px;
}

.step-highlighted-card {
  box-sizing: border-box;
  border: 1px solid rgba(64, 182, 107, 0.3);
  border-radius: 30px;
  background: rgba(64, 182, 107, 0.2);
}

@media (max-width: 768px) {
  .steps-container {
    flex-direction: column;
    align-items: center;
  }

  .step-card {
    width: 100%;
    margin-bottom: 20px;
  }
}

/*___________________________________________________*/

.community-trust-section {
  border-radius: 50px;
  background: radial-gradient(
    169% 62% at 50% 53%,
    rgb(1, 2, 20) 70%,
    rgb(96, 48, 220) 85%,
    rgb(206, 192, 249) 100%
  );
  color: #fff;
  padding: 80px 20px;
  text-align: center;
}

.community-trust-section h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.community-trust-section p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.explore-btn {
  background-color: #fff;
  color: #000;
  padding: 15px 30px;
  border-radius: 30px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  text-decoration: none;
}

.explore-btn i {
  top: 0px;
}

/*___________________________________________________*/

.community-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0b0e13; /* Темный фон секции */
  padding: 40px 20px;
  text-align: center;
  background: transparent;
  border-top: 2px solid purple;
  border-bottom: 2px solid purple;
  border-image: linear-gradient(
      to right,
      rgba(1, 3, 20, 1) 5%,
      rgba(157, 137, 255, 1) 50%,
      rgba(1, 3, 20, 1) 95%
    )
    1;
  border-width: 1px;
}

.community-section h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0%;
  text-align: center;
}

.community-section .telegram-link {
  display: inline-block;
  color: #26a0e8;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  border: 1px solid rgba(38, 160, 232, 0.24);
  border-radius: 10px;
  background: rgba(38, 160, 232, 0.1);
}

.community-section .telegram-link:hover {
  background-color: #0056b3;
}

.community-section p {
  font-size: 1rem;
  margin-top: 10px;
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: center;
}

/*_____________________________________________*/

.faq-section {
  padding: 50px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-section h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 30px;
}
