/* Tooltip.css */
.tooltip-container {
  position: relative;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.tooltip-question-icon {
  display: block;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  width: 100%;
  height: 100%;
  background-image: url('./../../../Images/Question.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.tooltip-message {
  position: absolute;
  right: 90%;
  width: 150px;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 20px;
  background: rgb(10, 6, 34);
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 3px;
  font-size: 10px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.tooltip-container:hover .tooltip-message {
  opacity: 1;
  right: 80%; /* Чуть сдвигаем сообщение при наведении */
}

/* Добавляем треугольник с помощью псевдоэлемента */
.tooltip-message::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -16px; /* Расстояние от края сообщения */
  transform: translateY(-50%);

  /* Треугольник с использованием границ */
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent rgba(190, 167, 255, 0.3); /* Стрелка направлена влево */
}
