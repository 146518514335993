/* Основные стили для шапки */
.pa-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background-color: #0b0e13;
  height: 70px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(190, 167, 255, 0.3);
  backdrop-filter: blur(70px);
  background: rgb(6, 7, 28);
  position: relative;
  z-index: 10;
}

.pa-header-right .lang-menu {
  top: 130%;
}

.open-left-panel {
  width: 30px;
  height: 24px;
  display: none;
  flex-direction: column;
  gap: 7px;
  transition: all 0.2s;
  margin-right: 25px;
  cursor: pointer;
}

.open-left-panel-item {
  background-color: #fff;
  flex: 1;
  border-radius: 25px;
}

.open-left-panel:hover {
  height: 30px;
  gap: 10px;
}

/* Левая часть шапки */
.pa-header-left {
  display: flex;
  align-items: center;
}

.icon-user {
  background-image: url('./../../../Images/UserAvatar.svg');
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.icon-row {
  background-image: url('./../../../Images/LanguageRow.svg');
  background-size: 13px 13px;
  width: 24px;
  height: 24px;
  margin-left: 6px;
  background-position: center;
  background-repeat: no-repeat;
}

.username {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

/* Центральная часть шапки */
.pa-header-middle {
  flex-grow: 1;
  text-align: right;
  padding: 0px 10vw;
  padding-bottom: none;
}

@media (max-width: 1200px) {
  .pa-header-middle {
    padding: 2vw;
  }
}

@media (max-width: 1250px) {
  .pa-header-middle {
    padding: 2vw;
  }
  .open-left-panel {
    display: flex;
  }
  .available-balance,
  .btn-deposit,
  .btn-wallet {
    display: none;
  }
}

.available-balance {
  color: rgba(159, 162, 185, 1);
  font-size: 16px;
}

.available-balance span {
  color: #fff;
}

/* Правая часть шапки */
.pa-header-right {
  display: flex;
  align-items: center;
}

.language-select {
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  padding: 6px;
}

.icon-flag {
  background-image: url('./../../../Images/FlagEN.svg');
  background-size: 20px 15px;
  width: 20px;
  height: 15px;
  margin-right: 6px;
}

.btn-deposit,
.btn-wallet {
  border: none;
  background-color: white;
  color: #0b0e13;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  text-align: center;
}
.btn-deposit {
  margin-left: 30px;
}
.btn-deposit:hover,
.btn-wallet:hover {
}

.btn-wallet {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}
