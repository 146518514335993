.home-section {
  border-radius: 50px;
  background: url('./../../../Images/Grid.png'),
    radial-gradient(
      145% 125% at 50% 0%,
      rgb(1, 2, 20) 50%,
      rgb(96, 48, 220) 75%,
      rgb(206, 192, 249) 100%
    );
  padding: 100px 20px;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.highlight {
  color: #6d58fc; /* Цвет выделения слова "West" */
}

.subheading {
  margin-bottom: 60px;
  max-width: 1000px;
  color: rgb(159, 162, 185);
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0%;
  text-align: center;
}

.primary-btn {
  background-color: #fff;
  color: #000;
  padding: 15px 30px;
  border-radius: 30px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.secondary-btn {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  padding: 15px 30px;
  border-radius: 30px;
  font-size: 1rem;
  cursor: pointer;
}

/*_______________________________________________*/

body {
  background-color: #0b0e13;
  color: white;
  font-family: Arial, sans-serif;
}

.how-it-works {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
}

.how-it-works h2 {
  background: linear-gradient(90deg, rgb(153, 153, 153), rgb(255, 255, 255), rgb(153, 153, 153));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 70px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
  margin-bottom: 50px;
}

.stages-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.stage {
  display: flex;
  gap: 20px;
}

.stage-info {
  max-width: 485px;
  margin-right: 120px;
}

.stage-info h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.stage-info p {
  font-size: 1rem;
  color: #9fa2b9;
}

.stage-table-container {
  width: 100%;
  flex: 1;
  min-width: 350px;
}

.stage-table::before {
  content: '';
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  display: block;
  position: relative;
  bottom: 20px;
  left: -23.5px;
}

.stage-table-container::before {
  content: 'Stage 1';
  border-radius: 25px;
  display: block;
  position: relative;
  bottom: -5px;
  left: -130px;
  border: 1px solid white;
  width: fit-content;
  padding: 10px 25px;
}

.stage-table-container_2::before {
  content: 'Stage 2';
}
.stage-table-container_3::before {
  content: 'Stage 3';
}
.stage-table-container_4::before {
  content: 'Stage 4';
}
.stage-table-container_5::before {
  content: 'Stage 5';
}

.stage-table {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-radius: 10px;
  gap: 10px;
  border-radius: 0%;
  border-left: 2px solid white;
}

.order {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px dashed rgb(159, 162, 185);
  border-radius: 10px;
  background: rgba(159, 162, 185, 0.2);
  padding: 24px;
  color: #fff;
}

.order span {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}

.order-info-percent {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.order-info {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  display: flex;
  gap: 20px;
}

.order-info span {
  display: flex;
  gap: 5px;
}

.order-info span i {
  display: block;
  width: 13px;
  height: 100%;
  background-image: url('./../../../Images/Sell.svg');
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  top: -3px;
}

.order-info-percent i {
  background-image: url('./../../../Images/SellTop.svg') !important;
}

.order-info-percent-red i {
  background-image: url('./../../../Images/RedSell.svg') !important;
}

.order-info-percent-green i {
  background-image: url('./../../../Images/Buy.svg') !important;
}

.order.green {
  border: 1px dashed rgb(64, 182, 107);
  border-radius: 10px;
  background: rgba(64, 182, 107, 0.2);
}

.green-mini {
  margin-left: 12%;
}

.order.red {
  box-sizing: border-box;
  border: 1px dashed rgb(181, 41, 58);
  border-radius: 10px;
  background: rgba(181, 41, 58, 0.2);
}

.stage-table .order.green span {
  color: #7bff00;
}

.stage-table .order.red span {
  color: #ff0000;
}

.order-empty {
  box-shadow: inset 0em 2em 16px 3px rgb(1 2 20 / 90%);
  border-top: none;
  border-left: none;
  border-right: none;
}

@media (max-width: 400px) {
  .stage-table {
    padding-left: 10px;
    padding-right: 10px;
  }
  .stage-table::before {
    bottom: 20px;
    left: -13.5px;
  }
}

@media (max-width: 730px) {
  .stage {
    flex-direction: column;
  }
  .how-it-works {
    padding-left: 0px;
    padding-right: 0px;
  }
  .stage-table-container::before {
    left: 20px;
  }
  .stage-info {
    margin-right: 0px;
  }
}

/*__________________________________________________*/

.FAQ-section {
  border-radius: 50px;
  background: radial-gradient(
    169% 62% at 50% 53%,
    rgb(1, 2, 20) 70%,
    rgb(96, 48, 220) 85%,
    rgb(206, 192, 249) 100%
  );
  color: #fff;
  padding: 80px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FAQ-section h2 {
  margin-bottom: 70px;
}
