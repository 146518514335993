* {
  box-sizing: border-box;
  font-family: 'Aktiv Grotesk Ex Trial', sans-serif;
  line-height: normal;
}

.App {
  height: 100vh;
}

.App-container {
  height: inherit;
  display: flex;
  flex-direction: column;
}

.page {
  padding: 0 20px;
  background-color: #010214;
  flex: 1;
}
section {
  overflow: hidden;
}

.PersonalAccountPage {
  display: flex;
  max-height: 100vh;
  overflow: hidden;
  flex-direction: row;
}

.PersonalAccount-main-block {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Стили для Chrome, Safari и других WebKit-браузеров */
.PersonalAccount-main-block::-webkit-scrollbar {
  width: 10px; /* Ширина скроллбара */
}

.PersonalAccount-main-block::-webkit-scrollbar-track {
  background: rgb(1, 2, 20); /* Цвет дорожки */
}

.PersonalAccount-main-block::-webkit-scrollbar-thumb {
  background: rgba(28, 29, 39, 0.684); /* Цвет ползунка */
  border-radius: 10px; /* Радиус скругления ползунка */
}

.PersonalAccount-container {
  width: 100%;
}

/* Адаптивность */
@media (max-width: 450px) {
  .page {
    padding: 0;
  }
  .delete-border-radius {
    border-radius: 0px !important;
  }
}
