.register {
  border-radius: 50px;
  background: radial-gradient(
    145% 125% at 50% 0%,
    rgb(1, 2, 20) 50%,
    rgb(96, 48, 220) 75%,
    rgb(206, 192, 249) 100%
  );
  padding: 10vw;
  padding-bottom: 40px;
}

.register-container {
  margin: auto;
  padding: 60px;
  border-radius: 20px;
  color: white;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  background: linear-gradient(180deg, rgba(48, 39, 85, 0.5), rgba(10, 6, 34, 0.5) 100%);
  max-width: 830px;
}

.register-title {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 40px;
  margin-top: 0px;
}

.register-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-register-code {
}

.input-group input {
  width: 100%;
  padding: 25px;
}

.input-group-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.input-register {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 10px;
  background: rgb(1, 2, 20);
  padding: 25px;
  flex: 1;
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.captcha-image img {
  width: 100%;
  height: 100%;
  max-width: 120px;
  min-width: 120px;
  border-radius: 10px;
  background: #fff;
}

.captcha-image {
  flex: 1;
  padding-right: 50px;
}

.checkbox-group {
  display: flex;
  align-items: center;
  padding: 30px;
  gap: 10px;
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.checkbox-group input[type='checkbox'] {
  max-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  accent-color: rgb(96, 48, 220);
}

.agreement {
}

.checkbox-group a {
  color: rgb(159, 162, 185);
  text-decoration: underline;
}

.create-account-btn {
  width: 100%;
  padding: 15px;
  border-radius: 30px;
  background: #ffffff;
  color: #000000;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background 0.3s ease;
}

.create-account-btn:hover {
  background: #e5e5e5;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 0.9rem;
}

.form-footer a {
  color: #9f7dff;
  text-decoration: underline;
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  cursor: pointer;
}

.form-footer span {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

@media (max-width: 768px) {
  .checkbox-group {
    padding: 0;
  }

  .register {
  }

  .register-container {
    padding: 0px;
    border: none;
    background: none;
  }
}
