.invest-container {
  padding: 40px;
  background-color: #010214;
  color: white;
  border-radius: 10px;
  flex: 1;
}

.invest-step {
  padding: 40px;
  margin-bottom: 40px;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  background: rgb(6, 7, 28);
}

.invest-step-title {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 0px;
  margin-bottom: 50px;
}

.invest-step-title-name {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.step-number {
  color: rgb(255, 255, 255);
  font-size: 100px;
  font-weight: 700;
  line-height: 128px;
  letter-spacing: 0%;
  margin-right: 5px;
}

.invest-step-description ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 723px;
}

.bottom-steps {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.bottom-steps .invest-step {
  flex: 1;
  margin-bottom: 0;
}

.invest-step-title-name-top {
  color: rgb(159, 162, 185);
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0%;
}

.invest-step-title-name-bottom {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0%;
}

.invest-step-description ul li {
  margin: 0;
  font-size: 1rem;
  color: #9fa2b9;
}

.invest-text-block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.invest-plans {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.selected-currency {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 10px;
  background: rgb(1, 2, 20);
  padding: 20px;
  margin-top: 20px;
}

.invest-plan {
  padding: 30px;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 20px;
  border-radius: 15px;
  cursor: pointer;
  width: 200px;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  flex: 1;
  min-width: 250px;
}

.invest-plan.active {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 20px;
  box-shadow: 0px 0px 0px 4px rgba(96, 48, 220, 0.5);
  background: linear-gradient(90deg, rgb(1, 2, 20), rgb(96, 48, 220) 152.802%), rgb(1, 2, 20);
  background-size: 200%;
  background-position: 0%;
  animation: gradientMove 1s alternate;
}

@keyframes gradientMove {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}

.plan-title {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 27px;
  margin-top: 0px;
}

.plan-percentage {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0%;
  text-align: left;
}

.plan-percentage span {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.plan-term,
.plan-accruals,
.plan-min-deposit,
.plan-max-deposit {
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #9fa2b9;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.line {
  flex: 1;
  border-bottom: 1px solid rgba(190, 167, 255, 0.3);
  height: 10px;
}

.plan-min-deposit span,
.plan-max-deposit span {
  color: white;
}

.plan-indicator {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 30px;
  top: 25px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.plan-max-deposit {
}

.invest-plan.active .plan-indicator::after {
  content: '';
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(64, 182, 107);
  margin-top: 0;
}

.vip-indicator {
  position: absolute;
  top: 21px;
  right: 15px;
  width: 108px;
  height: 36px;
  background: rgb(96, 48, 220);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plan-indicator-vip {
  position: relative;
  background: rgb(6, 7, 28);
  top: 0px;
  right: 5px;
}

.vip-indicator-text {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  position: relative;
  left: 10px;
}

.vip-label {
  font-size: 0.8rem;
  color: white;
  /*background-color: #6d58fc;*/
  border-radius: 10px;
  padding: 2px 5px;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 108px;
  height: 36px;
  border-radius: 25px;
  box-shadow: 10px 5px 5px red;
}

.currency-options {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.currency-option {
  flex: 1;
  display: flex;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 10px;
  background: rgb(1, 2, 20);
  padding: 10px;
  min-width: 150px;
  justify-content: center;
}

.currency-option-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  gap: 5px;
}

.selected-currency span {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.active-currency {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 20px;
  box-shadow: 0px 0px 0px 4px rgba(96, 48, 220, 0.5);
  background: linear-gradient(90deg, rgb(1, 2, 20), rgb(96, 48, 220) 152.802%), rgb(1, 2, 20);
  background-size: 200%;
  background-position: 0%;
  animation: gradientMove 1s alternate;
}

.currency-option-text span {
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0%;
  padding: 4px 0px 2px 0px;
  text-align: left;
  border-radius: 10px;

  transition: padding 0.3s ease, background 0.3s ease;
}

.active-currency span {
  padding: 4px 7px 2px 7px;
  background: rgb(96, 48, 220);
}

.currency-option img {
  max-width: 50px;
  min-width: 50px;
}

.currency-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.amount-details {
  display: flex;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0%;
  text-align: left;
}

.amount-details p {
  display: block;
  padding: 4px;
}

.amount-details p span {
  color: rgb(159, 162, 185);
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0%;
  text-align: left;
}

.currency-label img {
  display: block;
  width: 30px;
  background-position: center;
  background-size: contain;
}

.amount-input {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 10px;
  background: rgb(1, 2, 20);
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

/* Для Chrome, Safari, Edge, Opera */
.amount-input input[type='number']::-webkit-inner-spin-button,
.amount-input input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Для Firefox */
.amount-input input[type='number'] {
  -moz-appearance: textfield;
}

/* Общие стили */
.amount-input input[type='number'] {
  appearance: none;
}

.amount-input input[type='number'] {
  /* Сбрасываем все стандартные стили */
  all: unset;
  border: none;
  /* Добавляем ваши кастомные стили */
  width: 100%; /* Ширина по вашему усмотрению */
  padding: 10px; /* Внутренние отступы */
  border-radius: 4px; /* Радиус закругления */
  font-size: 16px; /* Размер текста */
  box-sizing: border-box; /* Чтобы padding не влиял на ширину */
  outline: none; /* Убираем обводку при фокусе */
}

.amount-input input[type='number']:focus {
  /* Кастомный стиль при фокусе */
  border: none;
}

.invest-btn {
  border-radius: 40px;
  background: rgb(255, 255, 255);
  padding: 20px 60px 20px 60px;
  width: 100%;
  border: none;
  color: rgb(1, 2, 20);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.invest-btn i {
  width: 10px;
  height: 18px;
  display: block;
  background-image: url('./../../../Images/RightRow.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  margin-left: 10px;
  position: relative;
  left: 0px;
  transition: left 0.3s ease;
}

.invest-btn:hover i {
  left: 10px;
}

.profit-block {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: transparent; /* Темный фон */
  border-radius: 10px;
  border: 1px solid #2a2a44; /* Тонкая светлая граница */
  margin-top: 24px;
}

.profit-item {
  text-align: left;
  color: #bcbccf; /* Цвет текста */
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.profit-label {
  font-size: 12px;
  margin: 0px;
  margin-bottom: 14px;
}

.profit-value {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff; /* Цвет суммы */
  margin: 0px;
}

.btc-label {
  font-size: 12px;
  margin-left: 4px;
  color: #bcbccf; /* Цвет "BTC" */
}

.profit-item:not(:last-child) {
  border-right: 1px solid #2a2a44;
  padding-right: 20px;
}

@media (max-width: 640px) {
  .profit-block {
    border: none;
    gap: 5px;
  }
  .profit-item {
    border: 1px solid #2a2a44 !important;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .invest-container {
    padding-right: 0px;
    padding-left: 10px;
  }
  .invest-step {
    border-radius: 0px;
    padding: 30px;
  }
  .step-number {
    font-size: 80px;
  }
  .invest-step-title-name-bottom {
    font-size: 30px;
    line-height: 30px;
  }
  .profit-item:not(:last-child) {
    border: none;
  }
  .main-block-title-invest {
    text-align: center;
  }
}
