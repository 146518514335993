/* Основные стили */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  background-color: #010214;
  color: #fff;
}

.nav {
  display: contents;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo-img {
  height: 30px;
  margin-right: 10px;
}

.logo-text {
  font-size: 18px;
  font-weight: bold;
}

.protocol {
  color: #6d58fc;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav ul li a {
  color: #fff;
  text-decoration: none;
}

.auth-buttons {
  display: flex;
  gap: 10px;
}

.lang-btn,
.login-btn,
.signup-btn {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
}

.signup-btn {
  color: #000;
  border-radius: 20px;
  padding: 15px 20px;
  border-radius: 40px;
  background: rgb(255, 255, 255);
  text-decoration: none;
  color: rgb(1, 2, 20);
  font-size: 16px;
  font-weight: 700;
  margin-left: 20px;
}

.login-btn {
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border: 2px solid transparent;
  text-align: center;
  justify-content: center;
}

.signup-btn:hover {
  background-color: #ddd;
}

.login-btn:hover {
  border: 2px solid white;
  border-radius: 40px;
}

.flag-icon {
  background: url('./../../Images/FlagEN.svg') no-repeat;
  background-size: 24px;
  background-position: center;
  width: 16px;
  height: 11px;
  display: inline-block;
}

.lang-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.lang-dropdown {
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
}

.lang-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.lang-menu {
  position: absolute;
  flex-direction: column;
  top: 90%;
  left: 0;
  list-style: none;
  padding: 10px;
  margin: 0;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  gap: 5px !important;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 20px;
  background: rgba(1, 2, 20, 0.8);
}

.lang-menu li {
  padding: 3px 5px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  justify-content: center;
}

.lang-menu li:hover {
  box-sizing: border-box;
  border-bottom: 1px solid rgba(190, 167, 255, 0.3);
}

.row-icon {
  transition: transform 0.2s ease;
}

.row-icon.open {
  transform: rotate(180deg);
}

.row-icon {
  background: url('./../../Images/RowDown.svg') no-repeat;
  background-size: 10px 6px;
  background-position: center;
  width: 16px;
  height: 11px;
  display: inline-block;
}

/* Бургер-меню */
.burger-menu-btn {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 22px;
}

.burger-bar {
  width: 100%;
  height: 3px;
  background-color: white;
  border-radius: 25px;
  transition: all 0.3s ease;
}

/* Анимация превращения в крестик */
.burger-menu-btn.open .burger-bar:nth-child(1) {
  transform: rotate(45deg) translate(9px, 7px);
}

.burger-menu-btn.open .burger-bar:nth-child(2) {
  opacity: 0;
}

.burger-menu-btn.open .burger-bar:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -5px);
}

/* Активная ссылка */
.active-link {
  font-weight: bold;
  color: #6d58fc;
}

/* Стили для мобильных устройств */
@media (max-width: 1090px) {
  .nav {
    display: flex;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #010214;
    padding: 20px;
    padding-bottom: 0px;
    z-index: 999;
    flex-direction: column;
    gap: 20px;
    height: 0;
    transition: height 1s ease;
    overflow: hidden;
  }

  .nav ul {
    flex-direction: column;
    text-align: left;
  }

  .nav.open {
    height: 330px; /* Увеличено для показа элементов навигации и авторизации */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /* Показываем auth-buttons при открытии бургер-меню */
  .auth-buttons {
    display: none;
  }

  .nav.open .auth-buttons {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .burger-menu-btn {
    display: flex;
  }
}
