.social-media-monetization {
  margin: 0 auto;
  text-align: center;
}

.home-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-section-heading {
  background: linear-gradient(90deg, rgb(153, 153, 153), rgb(255, 255, 255), rgb(153, 153, 153));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 70px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
  margin-bottom: 80px;
}

.bounty-stage-table p a {
  color: blueviolet;
  text-decoration: none;
}

.monetization-content {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.monetization-block {
  border-radius: 20px;
  width: 100%;
  max-width: 550px;
  text-align: center;
  position: relative;
}

.monetization-image {
  width: 100%;
  height: 265px;
  margin-bottom: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Иконки для изображений YouTube и социальных сетей */
.monetization-image.youtube {
  background-image: url('./../../../Images/YouTube.svg');
}
.monetization-image.social {
  background-image: url('./../../../Images/Phone.svg'); /* Замените на реальный путь */
}

.monetization-subtext {
  color: rgb(159, 162, 185);
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0%;
  text-align: center;
  margin: 40px 0;
}

.monetization-block h3 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0%;
  text-align: center;
}

@media (max-width: 768px) {
  .monetization-content {
    flex-direction: column;
    align-items: center;
  }

  .monetization-block {
    max-width: 100%;
  }
}

/*_________________________________________________________*/

.how-it-works {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
}

.section-how-it-works {
  margin-top: 100px;
  margin-bottom: 100px;
}

.instruction-title {
  margin-bottom: 80px !important;
  margin-top: 0px;
}

.bounty-stage-table p {
  color: rgb(159, 162, 185);
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0px;
  position: relative;
  top: -30px;
}

.stages-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.bounty-stage {
  display: flex;
  gap: 20px;
}

.bounty-stage-info {
  max-width: 485px;
  margin-right: 120px;
}

.bounty-stage-info h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.bounty-stage-info p {
  font-size: 1rem;
  color: #9fa2b9;
}

.bounty-program-container {
  width: 100%;
  flex: 1;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 33px;
}

.bounty-stage-table::before {
  content: '';
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  display: block;
  position: relative;
  bottom: 20px;
  left: -23.5px;
}

.bounty-stage-table {
  flex-direction: column;
  padding-left: 20px;
  border-radius: 10px;
  border-radius: 0%;
  border-left: 2px solid white;
}

@media (max-width: 450px) {
  .home-section-heading {
    font-size: 60px;
  }
  .bounty-stage-table p {
    font-size: 16px;
    line-height: 1.5;
  }
  .how-it-works h2 {
    font-size: 60px;
  }
}
