.carousel-container {
  /*position: relative;*/
  width: 100%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  max-width: 1880px;
}

.carousel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.carousel {
  display: flex;
  gap: 20px; /* Регулируем отступ между слайдами */
  transition: transform 0.5s ease-in-out;
}

.carousel-wrapper:after {
  content: '';
  position: absolute;
  width: 15vw;
  height: 100%;
  z-index: 1;
  right: -10px;
  background: rgb(1, 2, 20);
  background: linear-gradient(
    -90deg,
    rgba(1, 2, 20, 1) 0%,
    rgba(1, 2, 20, 1) 50%,
    rgba(1, 2, 20, 0) 100%
  );
}

.carousel-wrapper::before {
  content: '';
  position: absolute;
  width: 15vw;
  height: 100%;
  z-index: 1;
  left: -10px;
  background: rgb(1, 2, 20);
  background: linear-gradient(
    90deg,
    rgba(1, 2, 20, 1) 0%,
    rgba(1, 2, 20, 1) 50%,
    rgba(1, 2, 20, 0) 100%
  );
}

.carousel {
  position: relative;
  display: flex;
  gap: 10px;
  transform: translateX(200px);
  transition: transform 0.3s ease;
}

.carousel-item {
  width: 25vw;
  min-width: 300px;
  max-width: 465px;
  box-sizing: border-box;
  padding: 40px;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  color: white;
  text-align: left;
}

.carousel-item-img {
  width: 100%;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  margin-bottom: 40px;
}

.carousel-item p {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0px;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  cursor: pointer;
  width: 25px;
  height: 40px;
  z-index: 2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
}

.prev {
  left: 6vw;
  background-image: url('./../../Images/LeftRowSlider.svg');
}

.next {
  right: 7vw;
  background-image: url('./../../Images/RightRowSlider.svg');
}

@media (max-width: 1350px) {
  .prev {
    left: 3vw;
  }
  .next {
    right: 4vw;
  }
}

@media (min-width: 1860px) {
  .prev {
    left: 3vw;
  }
  .next {
    right: 3vw;
  }
  .carousel-wrapper:after {
    width: 20%;
  }
  .carousel-wrapper::before {
    width: 20%;
  }
}

.carousel-button:hover {
}

@media (max-width: 450px) {
  .carousel-wrapper {
    /*margin: 0px 20px;*/
  }
  .carousel-wrapper:after {
    background: none !important;
  }
  .carousel-wrapper::before {
    background: none !important;
  }
  .carousel-item p {
    font-size: 14px !important;
  }
  .carousel-item {
    padding: 60px;
    width: 100vw;
    border-radius: 0px;
  }
  .prev {
    width: 10px;
    left: 0px;
  }
  .next {
    width: 10px;
    right: 0px;
  }
}
