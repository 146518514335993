.contact-section {
  margin: 0 auto;
  padding: 50px 20px;
  text-align: center;
  margin-bottom: 100px;
}

.contact-blocks {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  max-width: 830px;
}

.contact-section-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}

.contact-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: white;
}

.contact-subtitle {
  font-size: 1.2rem;
  color: #9fa2b9;
  margin-bottom: 40px;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.contact-block {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  background: linear-gradient(180deg, rgba(48, 39, 85, 0.5), rgba(10, 6, 34, 0.5) 100%);
  padding: 30px;
  flex: 1;
  max-width: 830px;
  position: relative;
  text-align: left;
}

.contact-block h3 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0%;
  text-align: left;
  padding-right: 60px;
}

.contact-block p {
  color: rgb(159, 162, 185);
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: left;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  color: white;
  font-size: 1rem;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 10px;
  background: rgb(1, 2, 20);
}

.contact-form form {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

.inputs {
  display: flex;
  gap: 20px;
}

.contact-form textarea {
  height: 120px;
}

.contact-form .send-message {
  width: 100%;
  padding: 15px;
  background-color: white;
  border: none;
  color: #0b0e13;
  font-size: 1.1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  color: rgb(1, 2, 20);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0%;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.FAQ-special {
  background: linear-gradient(90deg, rgb(153, 153, 153), rgb(255, 255, 255), rgb(153, 153, 153));
  background: radial-gradient(42% 25% at 50% 0%, rgba(157, 137, 255, 0.1), rgba(1, 3, 20, 0) 100%),
    radial-gradient(
      200% 74% at 50% 42%,
      rgb(1, 2, 20) 70%,
      rgb(96, 48, 220) 85%,
      rgb(206, 192, 249) 100%
    );
  border: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(1, 3, 20, 1) 5%,
      rgba(157, 137, 255, 1) 50%,
      rgba(1, 3, 20, 1) 95%
    )
    1;
}

.send-message i {
  display: block;
  width: 10px;
  height: 18px;
  background-image: url('./../../../Images/RightRow.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  top: 2px;
  left: 0px;
  transition: left 0.3s ease;
}

.send-message:hover i {
  left: 5px;
}

.contact-form .send-message:hover {
  background-color: #f0f0f0;
}

/* Telegram Block */
.telegram-block h3 {
  background-image: url('./../../../Images/TG.svg'); /* Замените на ваш путь к иконке */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  display: block;
}

/* Community Chat Block */
.community-block h3 {
  background-image: url('./../../../Images/Message.svg'); /* Замените на ваш путь к иконке */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
  display: block;
  padding-right: 60px;
}

/* Адаптивность */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
  }

  .contact-block {
    max-width: 100%;
  }
}

/* Адаптивность */
@media (max-width: 450px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
  }
  .contact-section {
    padding-right: 0px;
    padding-left: 0px;
  }
  .contact-block {
    max-width: 100%;
    border-radius: 0px;
  }
  .contact-block h3 {
    font-size: 30px;
  }
}
