.settings-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

.settings-grid {
  /*display: grid;*/
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  gap: 40px;
  display: flex;
  flex-direction: column;
}

.settings-grid-top {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.settings-grid-bottom {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.settings-card {
  padding: 40px;
  color: white;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  background: rgb(6, 7, 28);
  flex: 1;
}

.personal-information-block {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  background: rgb(6, 7, 28);
  padding: 40px;
}

.personal-information-block-left {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex: 1;
}

.personal-information-block-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.floating-label i {
  display: block;
  width: 30px;
  height: 30px;
  background-image: url('./../../../Images/Token2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.crypto-label {
  display: flex;
  max-height: 90px;
  min-width: 300px !important;
}

.personal-information-container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.personal-information-block-right p {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0px;
}

.settings-form {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.long-top {
  flex: 100% !important;
}

.settings-form-group {
  display: flex;
  flex-direction: column;
}

.settings-form-group label {
  margin-bottom: 5px;
}

.settings-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  padding: 0px 20px;
  margin-bottom: 20px;
}

.settings-checkbox-group label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.settings-checkbox-group input[type='checkbox'] {
  max-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  accent-color: rgb(96, 48, 220);
}

.save-btn {
  border-radius: 40px;
  background: rgb(255, 255, 255);
  color: #06071c;
  padding: 20px 60px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  width: 100%;
  color: rgb(1, 2, 20);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0%;
}

.google-auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.google-auth-container span {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.google-auth-container p {
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
  word-break: break-all;
}

.qr-text {
  display: flex;
  flex-direction: column;
  gap: 25%;
}

.qr-block {
  display: flex;
  gap: 20px;
  width: 100%;
}

.auth-info {
  flex: 1;
  width: 100%;
}

.settings-card h3 {
  margin: 0;
  margin-bottom: 40px;
}

.auth-key {
  font-weight: bold;
  margin-bottom: 10px;
}

.floating-label {
  position: relative;
  padding: 30px 20px;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 10px;
  background: rgb(1, 2, 20);
  min-width: 250px;
  flex: 1;
}

.floating-label input {
  width: 100%;
  font-size: 16px;
  outline: none;
  background-color: transparent;
  color: white;
  border: none;
  position: relative;
  top: 10px;
}

.floating-label label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #aaa;
  padding: 0 5px;
  transition: 0.3s ease all;
  pointer-events: none;
}

.floating-label input:focus + label,
.floating-label input:not(:placeholder-shown) + label {
  top: 26px;
  left: 15px;
  font-size: 12px;
  color: rgb(159, 162, 185);
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0%;
  text-align: left;
}

.floating-label input:focus {
  border-color: white;
}

.floating-label input:placeholder-shown {
  color: transparent;
}

.security-settings {
  flex-direction: column;
}

@media (max-width: 1600px) {
  .google-auth-container p {
    font-size: 20px;
    word-break: break-all;
  }
}

@media (max-width: 1080px) {
  .settings-grid {
    display: flex;
    flex-wrap: wrap;
  }
  .settings-card {
    width: 100%;
  }
  .google-auth-container p {
    font-size: 20px;
    word-break: break-all;
  }
}

@media (max-width: 600px) {
  .settings-card,
  .personal-information-block {
    border-radius: 0px;
  }
  .settings-card {
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .google-auth-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .qr-code {
    margin-bottom: 20px;
  }
}
