.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 40px 20px;
  background-color: #010214; /* Темный фон */
  color: #fff;
  font-size: 14px;
  padding-top: 100px;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(190, 167, 255, 0.3);
  padding: 20px 0px 60px 0px;
}

.footer-top {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.footer-left {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-right {
  display: flex;
  text-align: right;
  flex-direction: column;
  align-items: flex-start;
}

.footer-left-title {
  color: rgb(255, 255, 255);
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0%;
  text-align: left;
}

.footer-left p,
.footer-right p {
  margin: 10px 0;
}

.footer-text {
  color: rgb(159, 162, 185);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0%;
}

.privacy-policy a {
  cursor: pointer;
}

.footer a {
  color: rgb(159, 162, 185);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: left;
  text-decoration: none;
}

.footer-buttons {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.footer-btn {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0%;
  border-radius: 20px;
  padding: 14px 40px 14px 40px;
  border: none;
  cursor: pointer;
}

.footer-btn-registration {
  color: rgb(1, 2, 20);
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: left;
}

.footer-btn:hover {
  background-color: #ddd;
}

.footer-btn-outline {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.footer-btn-outline:hover {
  background-color: #fff;
  color: #000;
}

.privacy-policy a {
  color: rgb(159, 162, 185);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: right;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

@media (max-width: 760px) {
  .footer-top {
    justify-content: center;
    text-align: center;
  }
  .footer-right {
    align-items: center;
  }
  .footer-left-title {
    text-align: center;
  }
  .footer-left {
    max-width: 100%;
  }
}
