.eth-card {
  background-color: #12122c;
  color: white;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  transition: max-height 0.4s ease;
  width: fit-content;
  width: 100%;
  min-width: 1050px;
}

.eth-card.open {
}

.open-card-header {
  border-radius: 15px 15px 0px 0px !important;
}

.eth-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 15px;
  background-color: rgb(1, 2, 20);
  padding: 0px 20px;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  background: rgb(1, 2, 20);
}

.currency {
  display: flex;
  align-items: center !important;
  flex: 1;
  align-items: flex-start;
  flex-direction: row !important;
  justify-content: flex-start !important;
}

.balance-info-cell-button {
  align-items: flex-end;
  width: 30px !important;
  flex: 0 !important;
}

.eth-logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.balance-info-cell {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.signature {
  color: rgb(159, 162, 185);
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0%;
  text-align: left;
}

.currency-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.currency-info span {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.currency-info small {
  color: rgb(159, 162, 185);
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0%;
  text-align: left;
}

.balance-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.balance-info p {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  text-align: left;
}

.toggle-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 20px;
  position: relative;
  left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 35%;
  cursor: pointer;
  border: 1.5px solid rgb(255, 255, 255);
  opacity: 0.4;
}

.eth-card-content {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wallet {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 10px;
  background: rgb(1, 2, 20);
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.wallet-section-item {
  flex: 1;
  padding: 20px;
}

.wallet-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  align-items: center;
}

.wallet p {
  color: #999;
  margin: 0;
  color: rgb(159, 162, 185);
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0%;
  text-align: left;
}

.wallet strong {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.button-section {
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.set-wallet,
.withdraw {
  padding: 14px 40px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  max-width: 230px;
  border-radius: 40px;
  background: rgb(255, 255, 255);
  font-weight: bold;
}

.set-wallet {
  box-sizing: border-box;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 40px;
  background: transparent;
  color: #fff;
}

.withdraw {
  background-color: white;
  color: #12122c;
}

.balance-info-cell-bottom {
  color: rgb(159, 162, 185) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0% !important;
  text-align: left !important;
}
