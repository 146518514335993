.faq-item {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  margin-bottom: 15px;
  padding: 15px;
  cursor: pointer;
  width: 100%;
  max-width: 1436px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question h3 {
  color: white;
  font-size: 1.2rem;
  text-align: left;
}

.toggle-icon {
  font-size: 30px;
  color: white;
}

.faq-answer-wrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.faq-answer {
  margin-top: 0;
  padding: 15px;
  color: #9fa2b9;
  font-size: 1rem;
  line-height: 1.5;
  border-top: 1px solid rgba(159, 162, 185, 0.2);
  text-align: left;
}
