/* Sidebar.css */
.left-panel {
  height: 100vh; /* Высота на весь экран */
  width: 300px; /* Ширина боковой панели */
  transition: width 0.3s;
}

.close {
  width: 0px !important;
  transition: width 0.3s;
}

.show {
  display: flex;
}
.hide {
  display: none;
}

.close-menu-btn {
  box-sizing: border-box;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
  display: none;
}

.sidebar {
  left: 0;
  top: 0;
  height: 100vh; /* Высота на весь экран */
  width: 300px; /* Ширина боковой панели */
  background-color: #0b0e13; /* Цвет фона */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  border-right: 1px solid rgba(190, 167, 255, 0.3);
  background: rgb(1, 2, 20);
  z-index: 1000;
  align-items: flex-start;
  padding: 40px 20px;
}

.sidebar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.sidebar-logo img {
  /*width: 150px; /* Размер логотипа */
}

.sidebar-nav {
  width: 100%;
}

.sidebar-nav ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.sidebar-nav-list-item {
  width: 100%;
}

.sidebar-nav-list-item {
}

.icon {
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.icon-dashboard {
  background-image: url('./../../../Images/Category.svg');
}
.icon-invest {
  background-image: url('./../../../Images/Work.svg');
}
.icon-withdraw {
  background-image: url('./../../../Images/Wallet.svg');
}
.icon-partners {
  background-image: url('./../../../Images/User.svg');
}
.icon-settings {
  background-image: url('./../../../Images/Filter.svg');
}
.icon-logout {
  background-image: url('./../../../Images/Logout.svg');
}

.sidebar-nav li button,
.sidebar-nav li a {
  width: 100%;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  background-color: transparent;
  color: #9fa2b9;
  font-size: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  transition: background 0.4s ease-in-out, color 0.3s ease;
  background-size: 200% 100%;
  background-position: 100% 0; /* Изначальная позиция за пределами элемента */
}

.sidebar-nav li button i,
.sidebar-nav li a i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar-nav li.active button,
.sidebar-nav li.active a {
  box-sizing: border-box;
  font-weight: bold;
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    rgb(96 48 220 / 0%) 0%,
    rgb(96 48 220 / 13%) 50%,
    rgb(96 48 220 / 72%) 100%
  );
  background-size: 200% 100%;
  background-position: 0 0; /* Анимация плавного появления */
  color: white;
  transition: background-position 0.4s ease-in-out, color 0.5s ease;
}

.menu-title {
  color: rgb(159, 162, 185);
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
  padding-left: 20px;
}

.sidebar-buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.sidebar-btn {
  border-radius: 40px;
  background: rgb(255, 255, 255);
  border-radius: 25px;
  color: #0b0e13;
  border: none;
  padding: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.sidebar-btn-icon {
  background-image: url('./../../../Images/RightRow.svg');
  background-size: 15px 11px;
  background-position: 5px;
  transition: background-position 0.2s ease;
}

.sidebar-btn:hover .sidebar-btn-icon {
  background-position: 10px;
}

.video-btn {
  box-sizing: border-box;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
}

.video-btn-icon {
  background-image: url('./../../../Images/YTmini.svg');
  background-position: 5px;
  width: 40px;
  height: 15px;
  transition: background-position 0.2s ease;
}

.video-btn:hover .video-btn-icon {
  background-image: url('./../../../Images/YTmini.svg');
  background-position: 10px;
}

.sidebar-wallet {
  display: none;
}

.sidebar-deposit {
  display: none;
}

.sidebar-available-balance {
  display: none;
}

@media (max-width: 1250px) {
  .left-panel {
    position: fixed;
    overflow: hidden;
    width: 100vw;
    z-index: 3;
  }
  .sidebar {
    padding-top: 100px;
    width: 100vw;
  }
  .close-menu-btn {
    display: block;
  }
  .sidebar-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
  .sidebar-wallet {
    display: block;
  }
  .sidebar-deposit {
    display: block;
  }
  .sidebar-available-balance {
    display: block;
    color: rgb(159, 162, 185);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0%;
    text-align: left;
    padding-left: 20px;
  }
  .sidebar-available-balance span {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
}
