.crypto-sidebar {
  box-sizing: border-box;
  border-left: 1px solid rgba(190, 167, 255, 0.3);
  background: rgb(6, 7, 28);
  width: 0px; /* Ширина панели */
  height: 100vh;
  box-sizing: border-box;
  position: fixed;
  right: 0;
  overflow: hidden;
  box-shadow: 0vw 0px 70px 0px #00000073;
  transition: width 1s, box-shadow 1s;
  z-index: 4;
}

.cryptos {
  display: flex;
  flex-direction: column;
}

.crypto-sidebar-container {
  padding: 40px 30px;
  padding-top: 100px;
  width: 430px; /* Ширина панели */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

.show-crypto-sidebar {
  width: 430px; /* Ширина панели */
  box-shadow: -30vw 0px 70px 100vw #00000073;
}

.crypto-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.crypto-info {
  display: flex;
  width: 140px;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 10px;
  background: rgb(1, 2, 20);
}

.crypto-icon {
  width: 50px;
  height: 50px;
}

.crypto-name {
  padding: 10px;
}

.crypto-name h4 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0px;
}

.crypto-name span {
  color: rgb(159, 162, 185);
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0%;
  text-align: left;
}

.crypto-status {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.crypto-status .available {
  color: white;
  font-weight: bold;
}

.close-crypto-panel {
  display: none;
}

@media (max-width: 1250px) {
  .close-crypto-panel {
    display: block;
  }
}

@media (max-width: 450px) {
  .crypto-sidebar-container {
    padding-left: 10px;
    padding-right: 10px;
    width: 100vw; /* Ширина панели */
  }
  .crypto-item {
    justify-content: flex-start;
    gap: 15px;
  }
}

.invested {
  color: rgb(159, 162, 185);
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.available span,
.invested span {
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0%;
  text-align: left;
}

@media (max-width: 768px) {
  .show-crypto-sidebar {
    width: 100vw;
  }
}
