.data-centers {
  border-radius: 50px;
  background: url('./../../../Images/Grid.png'),
    radial-gradient(
      145% 125% at 50% 0%,
      rgb(1, 2, 20) 50%,
      rgb(96, 48, 220) 75%,
      rgb(206, 192, 249) 100%
    );
  padding: 100px 20px;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.data-centers {
  padding: 50px 20px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.data-centers-wrapper {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.data-centers-info {
  flex: 1;
}

.data-centers-info h2 {
  background: linear-gradient(90deg, rgb(153, 153, 153), rgb(255, 255, 255), rgb(153, 153, 153));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 70px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
}

.data-centers-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.stats-item {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  background: rgba(1, 2, 20, 0.6);
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 270px;
  max-width: 350px;
}

.stats-item-right {
  flex: 1;
  box-sizing: border-box;
  border: 1px solid rgba(64, 182, 107, 0.3);
  border-radius: 30px;
  background: rgb(22 92 48 / 56%);
}

.stats-item h3 {
  color: rgb(255, 255, 255);
  font-size: 4em;
  font-weight: 700;
  line-height: 90px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0px;
  padding: 1vw;
}

.stats-item p {
  color: rgb(159, 162, 185);
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0%;
  text-align: left;
  display: block;
  padding: 1vw;
  margin: 0px;
  display: flex;
  gap: 5px;
}

.green {
  color: rgb(64, 182, 107) !important;
}

.data-centers-image {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 300px;
}

.data-centers-image img {
  width: 100%;
  max-width: 830px;
  border-radius: 10px;
}

.data-centers-features {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.feature-item {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 20px;
  background: rgba(1, 2, 20, 0.8);
  padding: 2.5vw 3vw;
  padding-left: 100px;

  min-width: 300px;
  text-align: center;
  display: flex;
  gap: 20px;
  align-items: center;
  flex: 1;
  background-position: 30px;
  background-repeat: no-repeat;
  background-size: 40px;
}

.feature-item:nth-child(1) {
  background-image: url('./../../../Images/Security1.svg');
}
.feature-item:nth-child(2) {
  background-image: url('./../../../Images/Security2.svg');
}
.feature-item:nth-child(3) {
  background-image: url('./../../../Images/Security3.svg');
}
.feature-item:nth-child(4) {
  background-image: url('./../../../Images/Security4.svg');
}

.feature-item p {
  color: rgb(255, 255, 255);
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0px;
}

/*______________________________________________________*/

.partners-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.partner-info {
  border: none !important;
  flex-direction: column;
  align-items: flex-start !important;
}

.partner-info h2 {
  color: rgb(255, 255, 255);
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: left;
}

.partner-info p {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.partner-logos {
  display: flex;
  gap: 30px;
  flex: 3;
  flex-wrap: wrap;
  justify-content: center;
}

.partner-logo {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
  height: 13vw;
  min-width: 250px;
  min-height: 160px;

  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  flex: 1;
}

.partner-logo img {
  width: 70%;
  min-width: 185px;
  max-width: 300px;
  object-fit: contain;
}

/*______________________________________________________*/

.transactions-section {
  border-radius: 50px;
  padding: 5vw 10vw;
  background: radial-gradient(
    161% 172% at 50% 154%,
    rgb(1, 2, 20) 80%,
    rgb(96, 48, 220) 90%,
    rgb(206, 192, 249) 100%
  );
}

.transactions-section-container {
  min-width: 330px;
}

.transactions-title {
  font-size: 1.8rem;
  text-align: left;
  color: white;
  border: 1px solid rgba(190, 167, 255, 0.2);
  padding: 60px;
  border-bottom: none;
  margin: 0;
  border-radius: 25px 25px 0px 0px;
  background: rgba(1, 2, 20, 0.8);
}

.scroll-block {
  display: flex;
  overflow-x: auto;
  flex: 1;
  box-sizing: border-box;
}

.scroll-block::-webkit-scrollbar {
  width: 12px; /* ширина scrollbar */
  height: 4px;
}
.scroll-block::-webkit-scrollbar-track {
}
.scroll-block::-webkit-scrollbar-thumb {
  background-color: rgba(190, 167, 255, 0.2); /* цвет плашки */
  border-radius: 20px; /* закругления плашки */
}

.cell {
  display: flex;
  flex-direction: column;
  padding: 1vw 2vw;
  box-sizing: border-box;
  border-right: 1px solid rgba(190, 167, 255, 0.2);
  background: rgba(1, 2, 20, 0.8);
  flex: 1;
  justify-content: center;
}

.cell span {
  color: rgb(159, 162, 185);
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0%;
  text-align: left;
}

.details p {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.cell p {
  margin-bottom: 0px;
}

.token-images {
  display: flex;
}

.transactions-table {
  width: 100%;
  border-radius: 0px 0px 25px 25px;
  overflow: hidden;
  border: 1px solid rgba(190, 167, 255, 0.2);
}

.transaction-row {
  display: flex;
  border-radius: 10px;
}

.transaction-header {
  font-weight: bold;
  color: rgba(159, 162, 185, 0.7);
  background-color: transparent;
}

.transaction-row .details,
.transaction-row .cost,
.transaction-row .revenue {
  color: white;
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  border-top: 1px solid rgba(190, 167, 255, 0.2);
}

.transaction-row .tokens-flow {
  display: flex;
  gap: 10px;
  align-items: center;
  align-items: flex-start;
  border-top: 1px solid rgba(190, 167, 255, 0.2);
}

.tokens-flow img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

@media (max-width: 700px) {
  .transactions-section {
    padding: 0px;
    border-radius: 25px;
  }

  .data-centers-info h2 {
    text-align: center;
    font-size: 60px;
  }

  .data-centers-stats {
    justify-content: center;
  }
}

@media (max-width: 450px) {
  .partner-logo {
    border-radius: 0px;
  }
  .partner-info {
    padding: 10px;
  }
  .transactions-table {
    border-radius: 0px;
  }
}
