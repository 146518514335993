.messange-container {
  padding: 30px;
  box-sizing: border-box;
  border-left: 1px solid rgb(255, 255, 255);
  border-radius: 10px;
  background: rgb(1, 2, 20);
}

.messange-title {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0%;
  text-align: left;
}

.messange-container p {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.final-messange-container {
  box-sizing: border-box;
  border-left: 1px solid rgb(64, 182, 107);
  border-radius: 10px;
  background: rgba(64, 182, 107, 0.1);
  color: rgb(64, 182, 107);
}

.final-messange-title {
  color: rgb(64, 182, 107);
}

.final-messange-container p {
  color: rgb(64, 182, 107);
}
