.add-funds {
  display: flex;
  justify-content: space-between;
  background-color: #010214;
  color: #bcbccf;
  flex-direction: column;
}

.question-icon {
}

.status {
  display: flex;
  gap: 3px;
}

.Operation-table {
  flex: 2;
  min-width: 300px;
}

.Operation-table .table-row-cell-value {
  color: white;
}

.table-row-operation:last-child {
  border-bottom: 1px solid rgba(190, 167, 255, 0.24);
}

.left-section,
.right-section {
  padding: 20px;
  border-radius: 10px;
  flex: 1;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  background: rgb(6, 7, 28);
}

.add-funds-container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.right-section {
  margin-right: 0;
}

.section-title {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 20px;
}

.network-block,
.referral-profit {
  margin-bottom: 20px;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 10px;
  background: rgb(1, 2, 20);
  padding: 20px;
}

.referral-profit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-part {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.left-part span {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0%;
  text-align: left;
}

.question-icon-funds {
  margin: 0;
  align-items: center;
  position: relative;
  top: -3px;
}

.network-block {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.network-block label,
.referral-profit span,
.copy-address p {
}

.network-select {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.profit-amount-add-funds {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #ffffff;
}

.btc-label-funds {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 10px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.btc-icon {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}

.qr-code-block {
  text-align: center;
  margin-bottom: 20px;
}

.qr-code {
  min-width: 330px;
  width: 100%;
  max-width: 350px;
}

.copy-address {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addres-block {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.addres-block p {
  margin: 0;
}

.addres-copy-block span {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  text-decoration-line: underline;
  border: none;
  background: transparent;
}

.addres-copy-block {
  display: flex;
  justify-content: space-between;
}

.operation-detail {
  width: 100%;
  border-collapse: collapse;
}

.operation-detail td {
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #2a2a44;
}

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin-right: 5px;
}
