/* Основные стили для блоков личного кабинета */
.main-block-container {
  padding: 20px 40px;
  padding-top: 40px;
  color: white;
  background: rgb(1, 2, 20);
  flex: 1;
}

.main-block-title {
  color: #9fa2b9;
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0;
  margin-bottom: 40px;
}

/* Грид-система для дашборда */
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Два равных столбца */
  gap: 20px;
}

@media (max-width: 1200px) {
  .grid {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 600px) {
  .main-block-container {
    padding: 20px 0px;
    padding-left: 10px;
    padding-top: 40px;
  }
  .main-block-title {
    text-align: center;
  }
  .block-grid-item,
  .table {
    border-radius: 0 !important;
  }
}

.block-grid-item {
  background-color: #0b0e13;
  padding: 40px;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  background: rgb(6, 7, 28);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.block-grid-item-title {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.info-title {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20;
  padding: 40px;
  margin: 0;
}

.info-text {
  box-sizing: border-box;
  border-top: 1px solid rgba(190, 167, 255, 0.3);
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  display: flex;
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
}

.info-text-value {
  color: rgb(255, 255, 255);
  word-break: break-all;
  padding-left: 10px;
}

.block-grid-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block-grid-item-header-top {
  padding-right: 20px;
}

.block-with-segments {
  grid-column: 1 / span 1; /* Один столбец */
}

.active-deposits {
  grid-column: 2 / span 1;
}

.details-security,
.finances {
  grid-column: span 1;
  padding: 0;
}

.table {
  grid-column: 1 / span 2; /* Блок таблицы будет занимать всю ширину (2 столбца) */
}

.operations {
  grid-column: 1 / span 2;
}

/* Стили для конкретных блоков */
.profit-amount {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0%;
  text-align: left;
  margin: 5px 0px 40px 0px;
}

.referrals-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.referral-box {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0;
  border-radius: 8px;
  flex: 1;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 10px;
  background: rgb(1, 2, 20);
  justify-content: space-between;
}

.referral-link {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 10px;
  background: rgb(1, 2, 20);
  padding: 20px;
  padding-top: 0px;
  flex: 1;
  align-items: stretch;
  justify-content: space-between;
}

.referral-link-block {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.referral-link input {
  background-color: transparent;
  border: none;
  padding: 10px;
  padding-left: 0px;
  flex-grow: 1;
  color: white;
  border-radius: 8px;
}

.copy-btn {
  color: white;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.24);
  border-radius: 40px;
  background: transparent;
  position: static;
  width: 93px;
  height: 29px;
  /* Автолейаут */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10;
  padding: 8px 30px 8px 30px;
  cursor: pointer;
}

/* Стили для активных депозитов */
.active-deposits h3 span {
  float: right;
  font-size: 1.2rem;
  color: green;
}

.deposit-details {
}

/* Стили для операций */
.operations-table {
  margin-top: 10px;
}

/*_________________________________________________________*/

.table {
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  background: rgb(6, 7, 28);
  overflow: hidden;
}

.table h3 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: left;
  margin: 40px;
}

.table-row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(190, 167, 255, 0.24);
  overflow-x: auto;
}

/* Стили для Chrome, Safari и других WebKit-браузеров */
.table-row::-webkit-scrollbar {
  width: 10px; /* Ширина скроллбара */
  height: 5px;
}

.table-row::-webkit-scrollbar-track {
  background: rgb(1, 2, 20); /* Цвет дорожки */
}

.table-row::-webkit-scrollbar-thumb {
  background: rgba(28, 29, 39, 0.684); /* Цвет ползунка */
  border-radius: 10px; /* Радиус скругления ползунка */
}

.table-row-cell {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  gap: 20px;
  border-right: 1px solid rgba(190, 167, 255, 0.24);
}

.table-row-cell:last-child {
  border-right: none;
}

.table-row-cell-name {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.table-row-cell-value {
}

.table-row-cell-value span {
  color: rgb(159, 162, 185);
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0%;
  text-align: left;
}

.table-row.header {
  font-weight: bold;
  color: #9fa2b9;
}

.table-item-cell {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 18px 20px;
  gap: 10px;
  border-right: 1px solid rgba(190, 167, 255, 0.3);
  justify-content: center;
}

.table-item-cell:last-child {
  border-right: none;
}

.deposit-row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(190, 167, 255, 0.3);
  overflow-x: auto;
}

/* Стили для Chrome, Safari и других WebKit-браузеров */
.deposit-row::-webkit-scrollbar {
  width: 10px; /* Ширина скроллбара */
  height: 5px;
}

.deposit-row::-webkit-scrollbar-track {
  background: rgb(1, 2, 20); /* Цвет дорожки */
}

.deposit-row::-webkit-scrollbar-thumb {
  background: rgba(28, 29, 39, 0.684); /* Цвет ползунка */
  border-radius: 10px; /* Радиус скругления ползунка */
}

.active-deposits {
  padding: 0;
}

.table-item-cell-name {
  color: rgb(159, 162, 185);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0;
}

.table-item-cell-value {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin: 0;
  display: flex;
  align-items: flex-end;
  gap: 3px;
}

.Earned {
  color: rgb(64, 182, 107);
}

.Earned span {
  color: rgba(64, 182, 107, 0.5) !important;
}

.table-item-cell-value span {
  color: rgb(159, 162, 185);
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0%;
  text-align: left;
}

.pending-cell {
  box-sizing: border-box;
  border-left: 3px solid rgb(255, 255, 255);
}

.complete-cell {
  box-sizing: border-box;
  border-left: 3px solid rgb(64, 182, 107);
  color: rgb(64, 182, 107);
}

.table-title {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: left;
  margin: auto 0;
}

.table-header {
  display: flex;
  padding: 40px;
  justify-content: space-between;
  align-items: center;
}

.table-header p {
  display: block;
  box-sizing: border-box;
  border-left: 1px solid rgb(64, 182, 107);
  border-radius: 8px;
  background: rgba(64, 182, 107, 0.1);
  text-align: center;
  margin: auto 0;
  padding: 5px 20px;
  color: rgb(64, 182, 107);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: left;
}

.table-title-block {
  display: flex;
  gap: 10px;
}

.question-icon {
  background-image: url('./../../../Images/Question.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 24px;
  height: 24px;
  margin: auto 0;
  margin-left: 20px;
}
