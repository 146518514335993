.Withdraw-btn {
  flex: 1;
  padding: 20px 60px;
  border: none;
  border-radius: 25px;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0%;
  box-sizing: border-box;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 40px;
  cursor: pointer;
}

.wallet-items {
  margin-bottom: 100px;
  box-sizing: border-box;
  border: 1px solid rgba(190, 167, 255, 0.3);
  border-radius: 30px;
  background: rgb(6, 7, 28);
  max-width: 100%;
  overflow-x: auto;
}

.wallet-items-container {
  max-width: 100%;
  width: 100%;
  overflow-x: auto;
}

/* Стили для Chrome, Safari и других WebKit-браузеров */
.wallet-items-container::-webkit-scrollbar {
  width: 10px; /* Ширина скроллбара */
  height: 5px;
}

.wallet-items-container::-webkit-scrollbar-track {
  background: rgb(1, 2, 20); /* Цвет дорожки */
}

.wallet-items-container::-webkit-scrollbar-thumb {
  background: rgba(28, 29, 39, 0.684); /* Цвет ползунка */
  border-radius: 10px; /* Радиус скругления ползунка */
}

.wallet-items-header {
  display: flex;
  padding: 0px 80px 0px 40px;
  min-width: 1088px;
}

.wallet-list {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.wallet-items-header p {
  flex: 1;
  margin: 0;
  padding: 30px 20px;
  display: flex;
  align-items: center;
}

.info-text-buttons {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.cansel-btn {
  color: rgb(255, 255, 255);
  background-color: transparent;
}

.address-text-value {
  word-break: break-all;
  padding-left: 20px;
}

@media (max-width: 600px) {
  .wallet-items {
    border-radius: 0px;
  }
}
